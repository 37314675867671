import React, { ReactElement, useState } from 'react'
import * as Yup from 'yup'
import { AnimatePresence, motion } from 'motion/react'
import { Box, Button, Flex, Notification } from '@mindfulchefuk/design-system'
import { Formik, Form } from 'formik'
import { FormControl, FormLabel, FormInput } from '@mindfulchefuk/forms'
import {
  NewsletterContactForm,
  NewsletterContactFormSource,
} from '@mindfulchefuk/features/Homepage/services/createNewsletterContact'

const emailFormSchema = Yup.object({
  email: Yup.string()
    .required('Email address is required')
    .email('Please enter a valid email address')
    .ensure(),
  name: Yup.string().required('Name is required').ensure(),
})

type TEmailForm = {
  email: string
  name: string
}

type TEmailCaptureForm = {
  submitHandler: (values: NewsletterContactForm) => Promise<void>
  isBBCGoodFood?: boolean
  source: NewsletterContactFormSource
}

export const EmailCaptureForm = ({
  submitHandler,
  isBBCGoodFood = false,
  source,
}: TEmailCaptureForm): ReactElement => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [formSubmissionCount, setFormSubmissionCount] = useState(0)

  const buttonCopy =
    source === 'mc_recipelibrary' ? 'Subscribe' : 'Claim Discount'

  return (
    <Formik
      initialValues={emailFormSchema.cast({})}
      validationSchema={emailFormSchema}
      onSubmit={async (values: TEmailForm, formik) => {
        try {
          await submitHandler({ ...values, isBBCGoodFood, source })

          formik.resetForm()
          setFormSubmissionCount(formSubmissionCount + 1)
          if (showError) setShowError(false)
          setShowSuccess(true)
        } catch {
          setShowError(true)
        }
      }}
    >
      {({ isValid, isSubmitting, dirty, handleSubmit }) => {
        if (dirty && showSuccess) {
          setShowSuccess(false)
        }

        return (
          <Form data-testid="email-capture-form" onSubmit={handleSubmit}>
            <AnimatePresence>
              {showSuccess && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Notification
                    my={16}
                    type="inline"
                    variant="success"
                    title="Thanks for subscribing"
                  >
                    Please check your email for a confirmation link and your 25%
                    off voucher code!
                  </Notification>
                </motion.div>
              )}
              {showError && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Notification
                    my={16}
                    type="inline"
                    variant="error"
                    title="Something went wrong"
                  >
                    Something went wrong when submitting your form. Please try
                    again later.
                  </Notification>
                </motion.div>
              )}
              {formSubmissionCount >= 3 && !showSuccess && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Notification
                    my={16}
                    type="inline"
                    variant="error"
                    title="Signup limit reached"
                  >
                    You&apos;ve reached your signup limit. Please try again
                    later.
                  </Notification>
                </motion.div>
              )}
            </AnimatePresence>

            <Flex direction={{ base: 'column', md: 'row' }} textAlign="left">
              <Box width="100%" my={12} mr={{ md: 12 }}>
                <FormControl name="name" id="name">
                  <FormLabel
                    color={isBBCGoodFood ? 'white' : 'aubergine'}
                    id="homepage-email-capture-name-input-copy"
                  >
                    Name
                  </FormLabel>
                  <FormInput
                    placeholder="Enter your name"
                    color={isBBCGoodFood ? 'bbc-good-food-black' : 'aubergine'}
                  />
                </FormControl>
              </Box>
              <Box width="100%" my={12} ml={{ md: 12 }}>
                <FormControl name="email" id="email">
                  <FormLabel
                    color={isBBCGoodFood ? 'white' : 'aubergine'}
                    id="homepage-email-capture-email-input-copy"
                  >
                    Email address
                  </FormLabel>
                  <FormInput
                    placeholder="Enter your email address"
                    color={isBBCGoodFood ? 'bbc-good-food-black' : 'aubergine'}
                  />
                </FormControl>
              </Box>
            </Flex>
            <Button
              mt={12}
              variant={isBBCGoodFood ? 'bbc-good-food' : 'primary'}
              size="medium"
              type="submit"
              disabled={
                !dirty ||
                !isValid ||
                isSubmitting ||
                (formSubmissionCount >= 3 && !showSuccess)
              }
              id="homepage-email-capture-button-copy"
            >
              {buttonCopy}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
